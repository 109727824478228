const slides = [
  {
    banner: require("@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-1.png"),
    icon: require("@/assets/img/companies/McAfee/McafeeHP/Password.png"),
    title: "GERENCIADOR DE SENHAS",
    description:
      "Armazene e gerencie com segurança todas as suas senhas online em um único local. O True Key protege suas senhas criptografando-as com AES-256, um dos algoritmos de criptografia mais poderosos. Somente você poderá descriptografá-las e acessar suas informações com os fatores que você selecionar.",
  },
  {
    banner: require("@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-2.png"),
    icon: require("@/assets/mastercard-partner/img/svg/wifi.svg"),
    title: "WI-FI SCAN",
    description:
      "Receba alertas oportunos ao tentar-se conectar a um Wi-Fi público para que você possa escolher uma rede Wi-Fi diferente ou ativar a VPN para uma conexão online. Evite conectar-se a um Wi-Fi inseguro e ter seus dados pessoais expostos.",
  },
  {
    banner: require("@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-3.png"),
    icon: require("@/assets/img/companies/McAfee/McafeeHP/dark.png"),
    title: "DARK WEB MONITOR",
    description:
      "Monitoramento a dark web por até 10 endereços de e-mail e, se encontrados, notificaremos você em média 10 meses antes de nossos concorrentes, para que você possa agir antes que suas informações acabem nas mãos erradas. Examinaremos a dark web para ver se seu endereço de e-mail foi exposto em uma violação de dados, permitindo que você aja antes que suas informações acabem nas mãos erradas.",
  },
];

export default slides;
