<template>
    <div id="accordion-component" class="d-flex flex-column gap-2">
        <AccordionItem
            v-for="(item, index) in items"
            :key="index"
            :title="item.title"
            :content="item.content"
            :onOpen="() => onItemOpen(index)"
            :isOpened="item.isOpened"
        />
    </div>
</template>

<script setup>
    import { defineProps, onMounted, ref } from "vue";

    import AccordionItem from "./Item.vue";

    const props = defineProps({
        items: Array,
        isSingleOpen: Boolean
    });

    const items = ref(props.items);

    onMounted(() => {
        items.value = props.items.map(item => {
            return {
                ...item,
                isOpened: false
            }
        })
    });

    function onItemOpen(index) {
        if (props.isSingleOpen) {
            items.value = items.value.map((item, i) => {
                return {
                    ...item,
                    isOpened: i === index && !item.isOpened
                }
            });

            return;
        }

        items.value[index].isOpened = !items.value[index].isOpened
    }
</script>

<style lang="scss" scoped>

</style>
