const currencyBRFormat = function (value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}
const currencyUSFormat = function (value) {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export default {
	currencyBRFormat,
	currencyUSFormat
}
