<template>
    <div class="bg-light">
        <div class="container p-5">
            <div class="row">
                <div v-for="(section, index) in section2Rows2Columns" :key="index" class="col col-12 col-md-6 p-4 p-md-5">
                    <img :src="section.icon" :alt="section.title" />
                    <p class="fw-bold text-dark h5 mt-2 sec-title">{{ section.title }}</p>
                    <p class="m-0 text-dark sec-desc">{{ section.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const section2Rows2Columns = ref([
        {
            icon: require('@/assets/icons/key-password.svg'),
            title: 'Gerenciador de senhas',
            description: 'Cria senhas fortes e exclusivas para todas as suas contas on-line, além de salvar e lembrar delas, para que você não precise fazer isso.',
        },
        {
            icon: require('@/assets/icons/24-7-protection.svg'),
            title: 'Proteção contra ameaças em tempo real',
            description: 'Proteção 24 horas por dia, 7 dias por semana para todos os dispositivos contra malware e ameaças on-line em constante evolução.',
        },
        {
            icon: require('@/assets/icons/trofeu-check.svg'),
            title: 'Antivírus premiado',
            description: 'A análise de ameaças baseada em nuvem mantém você protegido contra vírus, incluindo ransomware, sem atrasá-lo.',
        },
        {
            icon: require('@/assets/icons/mao-effect.svg'),
            title: 'Fácil de usar',
            description: 'Um console fácil de usar baseado na Web permite proteger e gerenciar todos os seus dispositivos em um só lugar.',
        },
    ])
</script>


<style>
    .sec-title {
        font-size: 24px;
        font-weight: 700;
    }

    .sec-desc {
        font-size: 20px;
        font-weight: 400;
    }
</style>
