<template>
    <div class="accordion-item-component border border-gray p-2 p-md-4 rounded pointer" @click="props.onOpen">
        <header class="d-flex align-items-center justify-content-between">
            {{ props.title }}
            <div>
                <img v-if="props.isOpened" src="@/assets/icons/arrow-down.svg" />
                <img v-else src="@/assets/icons/arrow-right.svg" />
            </div>
        </header>

        <footer class="py-2" v-if="props?.isOpened">
            <p class="m-0 fw-light text-mutted">{{ props.content }}</p>
        </footer>
    </div>
</template>

<script setup>
    import { defineProps } from "vue";

    const props = defineProps({
        title: String,
        content: String,
        isOpened: Boolean,
        onOpen: Function
    });
</script>

<style lang="scss" scoped>
    .accordion-item-component {
        cursor: pointer;
    }
</style>
