<template>
    <div class="why-mcafee-component w-full d-flex flex-column justify-content-center align-items-center p-4 p-md-5 gap-4">
        <p class="h2 fw-normal text-white">Por que a <b class="fw-bold">McAfee</b>?</p>

        <div class="dashed-box text-center border p-4 p-md-5">
            <img src="@/assets/img/companies/McAfee/McAfeeCommon/1640015279080.png.png" width="120" height="110" alt="" class="img-fluid">
            <p class="fw-bold text-white h5 m-0">Teste grátis por 30 dias.</p>
            <p class="text-white mt-1">Cancele sem custos durante esse período.</p>
        </div>

        <ul class="list-group border-0">
            <li v-for="(item, index) in listItems" :key="index" class="list-group-item bg-transparent border-0 text-white d-flex align-items-center gap-3">
                <img :src="checkCircleIcon" alt="icone de check" width="30"> {{ item }}
            </li>
        </ul>
    </div>
</template>

<script setup>
    import checkCircleIcon from "@/assets/icons/check-circle.svg";

    const listItems = [
        "Mais de 600 millhões de dispositivos de clientes protegidos até agora",
        "31 vezes vencedor da Escolha do Editor da  PCMag (PC Mag Editor's Choice)"
    ];
</script>


<style scoped lang="scss">
    .why-mcafee-component {
        background: linear-gradient(89.93deg, #C90063,#FF3838);

        div {
            border-style: dashed !important;
            border-radius: 1rem;
        }
    }
</style>
