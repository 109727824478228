<template>
  <button :class="btnClass" @click="handleClick">
    <slot></slot>
  </button>
</template>
<script>
import GoogleService from "@/services/tracking/google.service";

export default {
  props: {
    btnClass: {
      type: String,
      default: "btn"
    },
    tag: {
      type: String,
      required: true
    },
    disableTracking: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (this.disableTracking === true) {
        return;
      }
      GoogleService.gtagPush({
        event: this.tag,
      });
    }
  }
};
</script>

