<template>
  <div class="row w-100 d-flex flex-column">
    <div class="products-carousel-container mx-auto p-0">
      <div class="overflow-x-auto d-flex align-items-strech gap-3">
        <Product
          v-for="(product, key) in getProducts"
          :key="key"
          :product="product"
          :add-product="addProduct"
          :is-selected="checkProductSelected(product)"
        />
      </div>
    </div>

    <div class="col col-12 col-md-6 mx-auto mt-5">
      <Button tag="Ver Carrinho" type="button" class="btn cart-button w-100 text-white d-flex align-items-center justify-content-center gap-2" @click="openCart">
        <Icon name="cart" />
        <span>Ver Carrinho</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineProps } from 'vue';
  import { useStore } from 'vuex';
  import Product from "@/components/Product/Product";
  import Button from "@/components/Form/Button/Button.vue";

  // * Props
  const props = defineProps({
    products: {
      type: Array,
      default: () => []
    },
    addProduct: {
      type: Function,
      default: () => {}
    },
    openCart: {
      type: Function,
      default: () => {}
    }
  });

  // * Setup
  const store = useStore();

  // * Computed
  const hasProductInCartComputed = computed(() => store.getters['cart/hasProductInCart']);
  const getProducts = computed(() => props.products.length ? props.products : []);

  // * Methods
  const checkProductSelected = product => hasProductInCartComputed.value(product?.product);
</script>

<style lang="scss" scoped>
  .products-carousel-container {
    width: fit-content;
  }

  .cart-button {
    background-color: #C01818;
    transition: all .2s;

    &:hover {
      background-color: #f03b3b;
    }
  }

  .overflow-x-auto {
    -webkit-overflow-scrolling: touch;
  }
</style>
