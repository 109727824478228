<template>
    <div class="container d-flex justify-content-center">
        <ul class="list-group border-0">
            <li v-for="(item, index) in checkList" :key="index" class="list-group-item border-0" :class="{ 'text-danger': index === 0 }">
                <img src="@/assets/mastercard-partner/img/svg/checkmark-outline.svg" alt="icone de check" width="25" /> {{ item }}
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const checkList = ref([
        "Agora você pode parcelar sua assinatura em até 12x sem juros.",
        "Proteja seus Dispositivos. Compatível com Windows, Mac, iOS e Android.",
        "1 ano completo de proteção para você e seus dispositivos.",
        "Proteja sua família contra vírus, malware e ameaças online.",
    ])
</script>
