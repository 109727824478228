<template>
    <div class="card bg-white rounded-4">
        <div :class="{'card-selected': isSelected}" class="rounded-4 card-body d-flex flex-column justify-content-between">
            <h5 class="card-title align-self-start">{{ getProduct.name }}</h5>

            <div>
                <p v-if="hasAvailableProductTypeMcafeeComputed" class="card-text">{{ getProduct.description }}</p>

                <p class="card-text fw-light">Selecione a melhor oferta</p>
                <div class="dropdown w-100 align-self-end">
                    <div v-if="getShowClaroFieldsComputed" class="dropdown-menu show w-100 p-1">
                        <div class="header-message-list ps ps--active-y z-index">
                            <a class="dropdown-item d-flex justify-content-between align-items-center" @click="toggleDropdown">
                                <span class="mt-1 text-price"><strong>{{ currencyBRFormat(promotion.price.price) }}</strong></span>
                                <p class="msg-info m-0">{{ promotion.description }}</p>

                                <Icon name="down-arrow" :class="{ rotate: isOpen }" style="width: 24px" />
                            </a>

                            <div v-show="dropdownOpen" class="dropdown-menu show w-100" style="max-height: 200px; overflow-y: auto;">
                                <div class="header-message-list ps ps--active-y">
                                    <div v-for="(promotion, key) in getPromotions" :key="key">
                                        <a class="dropdown-item" @click="selectPromotion(key)" :class="{ 'selected-promotion': key === selectedPromotionIndex }">
                                            <span class="mt-1 text-price">
                                                <strong>{{ currencyBRFormat(promotion.price.price) }}</strong>
                                            </span>
                                            <p><small>{{ promotion.description }}</small></p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="card-body shadow p-3 bg-white rounded">
                        <a class="dropdown-item">
                            <span class="mt-1 text-danger fw-normal">
                                <p class="m-0 text-decoration-line-through">De <span>{{ currencyBRFormat(getProductPrice) }}</span></p>
                                <p class="m-0">Por <b class="fw-semibold">{{ currencyBRFormat(promotion.price.price) }}/ano</b></p>
                            </span>
                            <p class="m-0 text-wrap promotion-description text-dark">{{ promotion.description }}</p>
                        </a>
                    </div>
                </div>

                <div v-if="getShowClaroFieldsComputed && getAvailableProductTypeDecoded?.[0]?.id == 7" class="mt-3 px-1">
                    <div class="d-flex align-items-center justify-content-between">
                        <span class="text-aditionalPoint" :class="{ 'text-white': isSelected }">Pontos adicionais?</span>
                        <div class="counter d-flex align-items-center">
                            <button @click="changeCount(-1)" class="round-button minus-button" :class="{ 'button-white': isSelected }">-</button>
                            <button @click="changeCount(1)" class="round-button plus-button" :class="{ 'button-white': isSelected }">+</button>
                        </div>
                    </div>
                    <div class="select-and-input d-flex mt-2">
                        <select class="form-select" @change="updateTotalPrice" v-model="selectedPointAdditional">
                            <option selected :value="null">Selecione</option>
                            <option v-for="(description, key) in getProductResidentialPointAdditionalTypesComputed" :key="key" :value="description">
                                <p><small>{{ description.description }}</small></p><br />
                                <strong>{{ currencyBRFormat(description.price) }}</strong><br />
                            </option>
                        </select>
                        <input class="form-control ms-2" disabled type="text" :value="selectedPointsIndex || 0" />
                    </div>
                    <h5 class="card-title text-center text-total-price mt-3" :class="{ 'text-white': isSelected }">
                        Valor total: {{ formatMoney(updateTotalPrice()) }}/mês
                    </h5>
                </div>

                <div class="mt-3 d-flex align-items-center justify-content-center">
                    <Button v-if="isSelected" :disableTracking="true" :tag="getProductName" class="w-100 color-button-selected" pill variant="light" type="button">
                        Selecionado
                    </Button>

                    <Button v-else class="w-100 rounded-pill text-white color-button" type="button" :tag="getProductName" @click="addProductEvent()">
                        Selecionar
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MoneyService from "@/services/numbers/money";
import { mapGetters } from "vuex";
import Button from "@/components/Form/Button/Button.vue";
import Icon from "@/components/Icon/Icon.vue";

export default {
    name: "Product",
    data() {
        return {
            count: 0,
            showModal: false,
            selectedMessage: 0,
            dropdownOpen: false,
            selectedPromotionIndex: -1,
            selectedPointsIndex: 0,
            isOpen: null,
            selectedPointAdditional: null,
            selectedPointAdditionalPrice: 0,
        };
    },
    components: {
        Button,
        Icon,
    },
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        isSelected: {
            type: Boolean,
            default: () => false,
        },
        addProduct: {
            type: Function,
            default: () => { },
        },
    },
    watch: {
        selectedPointsIndex: "updateTotalPrice",
    },
    computed: {
        ...mapGetters({
            hasAvailableProductTypeMcafeeComputed: "saleFlow/product/hasAvailableProductTypeMcafee",
            hasAvailableProductTypeClaroComputed: "saleFlow/product/hasAvailableProductTypeClaro",
            getAvailableProductTypeDecoded: "saleFlow/product/getAvailableProductTypeDecoded",
            getProductResidentialPointAdditionalTypesComputed: "saleFlow/product/getProductResidentialPointAdditionalTypes",
            getCartDetailsComputed: "cart/getCartDetails",
            getShowClaroFieldsComputed: "address/getShowClaroFields",
        }),
        getProduct() {
            return !this.product?.product ? {} : this.product?.product;
        },
        getPromotions() {
            return !this.getProduct?.promotions ? [] : this.getProduct?.promotions;
        },
        getProductPrice() {
            return this.getProduct?.price?.price || 0;
        },
        currencyBRFormat() {
            return (value) => MoneyService.currencyBRFormat(value || 0);
        },
        getCartTotalPrice() {
            return this.getCartDetailsComputed?.total || 0;
        },
        filteredMessages() {
            return this.messages.filter(
                (_, index) => index !== this.selectedMessage && this.dropdownOpen
            );
        },
        promotion() {
            return this.getPromotions[this.selectedMessage] || {};
        },
        getPricePoint() {
            const prices = this.getProductResidentialPointAdditionalTypesComputed
                .map((description) => description.price)
                .filter((price) => typeof price === "number");
            return prices;
        },
        getSelectedPromotionInfo() {
            if (this.selectedPromotion) {
                return {
                    id: this.selectedPromotion.id,
                    price: this.selectedPromotion.price,
                };
            } else {
                return {
                    id: null,
                    price: 0,
                };
            }
        },
        getProductName() {
            return this.getProduct?.name || "";
        }
    },
    methods: {
        selectPromotion(index) {
            this.selectedMessage = index;
            this.dropdownOpen = false;
            this.selectedPromotionIndex = index;
            this.selectedPromotion = this.getPromotions[index];
            this.selectedPointsIndex = 0;
        },
        getPromotionById(promotionId) {
            return this.getProductResidentialPointAdditionalTypesComputed.find(
                (promotion) => promotion.id === promotionId
            );
        },
        changeCount(value) {
            const newPointsIndex = Math.max(0, Math.min(2, this.selectedPointsIndex + value));
            this.selectedPointsIndex = newPointsIndex;
            if (this.selectedPointsIndex < 1) {
                this.selectedPointAdditional = null;
            }
            this.updateTotalPrice();
        },
        updateTotalPrice() {
            if (!isNaN(this.promotion.price.price)) {
                const basePrice = parseFloat(this.promotion.price.price) || 0;
                if (this.selectedPointAdditional) {
                    if (this.selectedPointsIndex < 1) {
                        this.selectedPointsIndex = 1;
                    }
                    const selectedPointPrice = parseFloat(
                        this.selectedPointAdditional.price
                    );
                    const totalPrice =
                        basePrice + selectedPointPrice * this.selectedPointsIndex;
                    this.selectedPointAdditionalPrice = selectedPointPrice;
                    return totalPrice;
                }
            }
            this.selectedPointsIndex = 0;
            return 0;
        },

        formatMoney(value) {
            return MoneyService.currencyBRFormat(value || 0);
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        selectMessage(index) {
            this.selectedMessage = index;
            this.dropdownOpen = false;
        },
        addProductEvent() {
            this.openViabilityModal();
            const promotion = this.promotion || this.getPromotions[0] || null;
            const productResidentialPointAdditionalAmount =
                this.selectedPointsIndex;
            const productResidentialPointAdditionalType =
                this.selectedPointAdditional || null;
            this.addProduct({
                product: this.getProduct,
                promotion: promotion,
                productResidentialPointAdditionalAmount:
                    productResidentialPointAdditionalAmount,
                productResidentialPointAdditionalType:
                    productResidentialPointAdditionalType,
            });
        },
        openViabilityModal() {
            this.showModal = true;
        },
        closeViabilityModal() {
            this.showModal = false;
        },
    },
    created() {
        this.selectedPromotionIndex = 0;
    },
};
</script>

<style lang="scss" scoped>

$redSecondary: #FC222A;
$redPrimary: #C01818;

.card {
    min-width: 285px !important;
    max-width: 245px !important;
}

.promotion-description {
    font-size: 0.8rem;
}

.card-selected {
    color: white;
    background-color: crimson;
}

.color-button {
    background-color: $redSecondary;

    &:hover {
        background-color: $redSecondary;
    }

    &:active {
        background-color: $redPrimary;
    }
}

.text-aditionalPoint {
    color: crimson;
    font-size: 12px;
}

.text-total-price {
    color: crimson;
    font-size: 16px;
}

.selected-promotion {
    background-color: lightgray;
    font-weight: bold;
}

.counter {
    font-size: 18px;
}

.round-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.minus-button {
    background-color: crimson;
}

.plus-button {
    background-color: crimson;
}

.color-button-selected {
    background-color: white;
    color: crimson;
    border-radius: 18px;
}

.text-white {
    color: white;
}

.space-between {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Ajuste o espaçamento entre os itens para 5px */
}
</style>
