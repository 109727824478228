<template>
    <div class="mt-3 container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <img
                    class="img-fluid"
                    src="@/assets/img/concluida-carredura.png"
                    alt="Homem sorrindo segurando um tablet"
                />
            </div>

            <div class="col col-12 col-md-4 offset-md-1 pt-5">
                <h2>É mais do que apenas um software antivírus, é <span class="text-danger">tranquilidade</span></h2>
                <p class="m-0 text-dark">Defenda você e a toda a família contra os mais recentes ataques de vírus, malware, spyware e ransomware, ao mesmo tempo em que mantém o controle de sua privacidade e identidade.</p>
            </div>
        </div>
    </div>
</template>
